import React, { useEffect, useState } from 'react';
import Seo from '@/components/Seo';
import { Button, Fact } from '@mikafi/ui';
import { useIntl } from 'react-intl';
import Link from '@/components/Link';
import { useUser } from '@/hooks/useUser';
import OrderItem from '@/components/OrderItem';
import FormAddress from '@/components/FormAddress';
import FormPreferences, { Preferences } from '@/components/FormPreferences';
import { StructuredText } from 'react-datocms';
import classNames from 'classnames';
import FormEmail from '@/components/FormEmail';
import { useSupabaseClient, useSession } from '@supabase/auth-helpers-react';

const Account = ({ data }: { path: string; data: any }) => {
  const supabaseClient = useSupabaseClient();
  const intl = useIntl();
  const [customer, setCustomer] = useState<any>(null);
  const [preferences, setPreferences] = useState<Preferences | null>(null);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const page = data.datoCmsAccount;
  const [activeTab, setActiveTab] = useState<number>(0);
  const [message, setMessage] = useState<any>(null);
  const [success, setSuccess] = useState<string>('');
  const { user, accessToken } = useUser();
  const session = useSession();

  const handleLogout = async () => {
    await supabaseClient.auth.signOut();
  };

  // Fetch new data after customer email change
  supabaseClient.auth.onAuthStateChange((event, session) => {
    const getCustomer = async (accessToken: string | undefined) => {
      const result = await fetch(`/.netlify/functions/me`, {
        headers: {
          Authorization: 'Bearer ' + accessToken,
        },
      });

      const customer = await result.json();
      setCustomer(customer);
    };

    if (event == 'USER_UPDATED') getCustomer(session?.access_token);
  });

  useEffect(() => {
    const getCustomer = async () => {
      const result = await fetch(`/.netlify/functions/me`, {
        headers: {
          Authorization: 'Bearer ' + accessToken,
        },
      });

      const customer = await result.json();
      setCustomer(customer);
    };

    const getPreferences = async () => {
      const result = await fetch(`/.netlify/functions/preferences`, {
        headers: {
          Authorization: 'Bearer ' + accessToken,
        },
      });

      const data = await result.json();
      setPreferences({
        ...data?.preferences,
        brewMethods: new Set(data?.preferences?.brewMethods),
        grind: new Set(data?.preferences?.grind),
      });
    };

    if (session) {
      getCustomer();
      getPreferences();
    }
  }, [session, activeTab, isSaving, user]);

  useEffect(() => {
    setMessage('');
    setSuccess('');
  }, [activeTab]);

  const onSubmit = async () => {
    setIsSaving(true);
    setError(null);

    const result = await fetch(`/.netlify/functions/preferences`, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + accessToken,
      },
      body: JSON.stringify({ preferences }, (_key, value) =>
        value instanceof Set ? [...value] : value,
      ),
    });

    if (!result.ok) {
      setError("Couldn't save!");
    }

    setSuccess('Your preferences were saved!');
    setIsSaving(false);
  };

  const onSubmitCustomerAddress = async (data: any) => {
    setIsSaving(true);
    setError(null);

    const result = await fetch(`/.netlify/functions/me`, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + accessToken,
      },
      body: JSON.stringify({
        addresses: [
          {
            ...data,
          },
        ],
      }),
    });

    if (!result.ok) {
      return setError("Couldn't save!");
    }

    setSuccess('Your Address was saved!');
    setIsSaving(false);
  };

  const onSubmitCustomerEmail = async (data: any) => {
    setIsSaving(true);
    setError(null);

    const { data: userData, error } = await supabaseClient.auth.updateUser({
      email: data.email,
    });

    if (error) {
      return setError(error.message);
    }

    setMessage(
      'We sent you a confirmation email. Please confirm your new email.',
    );
    setIsSaving(false);
  };

  const onClickTab = (index: number) => {
    setActiveTab(index);
  };

  const TABS = [
    {
      name: intl.formatMessage({ id: 'yourBenefits' }),
      icon: (
        <svg
          width={48}
          height={48}
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="m24 11 3.292 9.47 10.023.204-7.99 6.056 2.904 9.596L24 30.6l-8.229 5.726 2.903-9.596-7.989-6.056 10.023-.204L24 11Z"
            stroke="#000"
            strokeWidth={1.6}
            strokeLinejoin="round"
          />
        </svg>
      ),
    },
    {
      name: intl.formatMessage({ id: 'yourPreferences' }),
      icon: (
        <svg
          width={48}
          height={48}
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M32.221 23.605c-.206-.79-.823-1.887-1.27-2.71-.136-.24-.274-.481-.342-.687a10.86 10.86 0 0 1-1.064-3.122c-.24-1.235-.274-2.333-.137-2.95.103-.48.24-1.27.137-1.921-.068-.48-.309-.858-.892-.858-.206 0-.412.034-.652.103-.686.205-1.44.686-1.99 1.372a4.01 4.01 0 0 0-.96 2.642c0 .412.069.857.206 1.303.549 1.887.789 3.088.823 3.877.069.96-.171 1.441-.549 1.681-.377.275-.926.48-1.406.55-.138.034-.31.034-.412.034-.824.171-2.71.377-4.94-.31a.44.44 0 0 1-.31-.548c.07-.24.31-.343.55-.309 1.44.412 2.71.48 3.602.412-.24-1.2-1.167-1.784-2.264-1.956-.755-.103-1.544 0-2.299.275-.755.24-1.44.686-1.921 1.2-.72.755-.995 1.819-.343 2.985.617.275.549.96-.584.892-.274-.034-.549.138-.72.412-.137.206-.206.412-.274.686-.035.24-.035.515 0 .79.102.514.48.994 1.132 1.2.651.172.686.995-.172 1.167-.206 0-.446.171-.617.446a1.513 1.513 0 0 0-.206.549c-.035.171 0 .377.068.549.172.411.652.823 1.613 1.063.72-.034.858.583.274.96-.617.687-.514 1.339.035 1.853.754.687 2.161 1.201 3.876 1.441 1.75.24 3.808.172 5.695-.343 4.563-1.166 8.406-4.837 6.313-12.728Zm-.206-3.294c.48.892 1.098 2.059 1.338 2.985 2.334 8.748-2.024 12.866-7.067 14.204-2.059.549-4.254.617-6.176.343-1.955-.24-3.602-.858-4.528-1.715-.892-.824-1.201-1.784-.618-2.814-.858-.377-1.338-.892-1.578-1.475-.172-.412-.206-.823-.172-1.2.035-.378.206-.721.378-1.03.103-.172.206-.309.343-.412a2.952 2.952 0 0 1-.858-1.475 2.51 2.51 0 0 1 0-1.235c.069-.412.206-.79.412-1.132.24-.378.549-.686.926-.824-.48-1.406-.068-2.676.824-3.636.617-.686 1.475-1.201 2.401-1.51.927-.309 1.922-.446 2.848-.309 1.51.206 2.779 1.03 3.225 2.677.069 0 .137 0 .206-.035.308-.069.686-.171.892-.343.034-.034.103-.206.068-.686-.034-.686-.274-1.784-.789-3.603a5.793 5.793 0 0 1-.24-1.646c0-1.373.514-2.54 1.27-3.397a5.418 5.418 0 0 1 2.572-1.75c.343-.102.686-.137.96-.137 1.373 0 1.922.858 2.06 1.887.137.858-.035 1.784-.172 2.333-.103.515-.103 1.407.137 2.505.172.857.48 1.852.96 2.813.104.137.241.377.378.617Z"
            fill="#000"
            stroke="#000"
            strokeWidth={0.3}
          />
        </svg>
      ),
    },
    {
      name: intl.formatMessage({ id: 'yourOrders' }),
      icon: (
        <svg
          width={48}
          height={48}
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M29.923 16.924v-1.188C29.923 12.58 27.257 10 24 10c-3.258 0-5.923 2.581-5.923 5.736v1.188H13V36.18c0 .45.38.819.846.819h20.308c.465 0 .846-.369.846-.82V16.925h-5.077Zm-10.154-1.147c0-2.254 1.904-4.097 4.23-4.097 2.328 0 4.232 1.843 4.232 4.097v1.188h-8.462v-1.188ZM33.307 35.36H14.692V18.563h3.385v5.367h1.692v-5.367h8.461v5.367h1.693v-5.367h3.384V35.36Z"
            fill="#000"
          />
        </svg>
      ),
    },
    {
      name: intl.formatMessage({ id: 'yourData' }),
      icon: (
        <svg
          width={48}
          height={48}
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M17 37v-6a5 5 0 0 1 5-5h5a5 5 0 0 1 5 5v6"
            stroke="#000"
            strokeWidth={1.6}
            strokeLinecap="round"
          />
          <circle cx={24.5} cy={16.5} r={5.5} stroke="#000" strokeWidth={1.6} />
        </svg>
      ),
    },
  ];

  return (
    <>
      <Seo title="Account" />

      <div className="bg-white -mt-10">
        <div className="mx-6 md:mx-8 lg:mx-16 max-w-screen-xl 2xl:mx-auto 2xl:px-16 py-36">
          {!customer ? (
            <>Loading...</>
          ) : (
            <>
              <div className="mb-20 md:flex relative">
                <div className="mb-6 flex-none md:w-3/12 md:mr-16"></div>
                <div>
                  <h1 className="mb-4 lg:mb-6 text-3xl">
                    Hi {customer?.firstName}
                  </h1>
                  <div className="mt-2 mb-12 text-5xl md:text-7xl lg:text-8xl font-medium">
                    {page.title}
                  </div>

                  <ul className="flex space-x-2 sm:space-x-4 px-0 mx-0 mb-20 overflow-x-auto">
                    {TABS.map((tab: any, i: number) => (
                      <li className="list-none" key={tab.name}>
                        <button
                          className={classNames(
                            'relative basis-1/3 flex-1 flex flex-col items-center justify-center p-2 md:p-4 cursor-pointer rounded-2xl',
                            {
                              'bg-opacity-10 bg-black': i === activeTab,
                              'border-transparent': i !== activeTab,
                            },
                          )}
                          onClick={() => onClickTab(i)}
                        >
                          <span className="mb-2">{tab.icon}</span>
                          <span className="text-sm">{tab.name}</span>
                        </button>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>

              {activeTab === 0 && (
                <div className="mb-32 md:flex">
                  <div className="mb-6 flex-none md:w-3/12 md:mr-16">
                    <h3>{intl.formatMessage({ id: 'yourBenefits' })}</h3>
                  </div>
                  <div className="md:flex-1">
                    <div className="mb-12 md:-mt-2">
                      <StructuredText data={page.textBenefits} />
                    </div>

                    <div className="grid md:grid-cols-2 gap-12">
                      {page.facts?.map((fact: any) => (
                        <Fact
                          key={fact.id}
                          text={fact.text}
                          title={fact.title}
                          subtitle={fact.subtitle}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              )}

              {activeTab === 1 && (
                <div className="mb-32 md:flex">
                  <div className="mb-6 flex-none md:w-3/12 md:mr-16">
                    <h3>{intl.formatMessage({ id: 'yourPreferences' })}</h3>
                  </div>
                  <div className="md:flex-1">
                    <div className="mb-12 md:-mt-2">
                      <StructuredText data={page.textPreferences?.value} />
                    </div>
                    <FormPreferences
                      setPreferences={setPreferences}
                      preferences={preferences}
                      isSaving={isSaving}
                      onSubmit={onSubmit}
                    />

                    {success && (
                      <div
                        className={classNames(
                          'mt-4 p-6 rounded-4xl mb-8 bg-green-400',
                        )}
                      >
                        {success}
                      </div>
                    )}

                    {error && <p>{error}</p>}
                  </div>
                </div>
              )}

              {activeTab === 2 && (
                <>
                  <div className="mb-32 md:flex">
                    <div className="mb-6 flex-none md:w-3/12 md:mr-16">
                      <h3>{intl.formatMessage({ id: 'yourOrders' })}</h3>
                    </div>
                    <div className="md:flex-1">
                      <div className="mb-12 md:-mt-2">
                        <StructuredText data={page.textOrders?.value} />
                      </div>
                      {customer?.orders?.edges?.length ? (
                        <>
                          <div className="hidden sm:flex border-b pb-4 font-medium uppercase text-xs">
                            <div className="w-1/5">Bestellnr.</div>
                            <div className="w-1/5">Datum</div>
                            <div className="w-1/5">Betrag</div>
                            <div className="w-1/5">Status</div>
                            <div className="w-1/5">Action</div>
                          </div>
                          {customer.orders.edges.map(({ node: order }: any) => (
                            <OrderItem key={order.id} order={order} />
                          ))}
                        </>
                      ) : (
                        <>
                          {intl.formatMessage({ id: 'yourNoOrder' })}{' '}
                          <Link to={`/coffees/`}>
                            {intl.formatMessage({ id: 'yourNoOrderLink' })}
                          </Link>
                          !
                        </>
                      )}
                    </div>
                  </div>
                </>
              )}

              {activeTab === 3 && (
                <>
                  {customer?.addresses && (
                    <div className="mb-32 md:flex">
                      <div className="mb-6 flex-none md:w-3/12 md:mr-16">
                        <h3>{intl.formatMessage({ id: 'yourAddress' })}</h3>
                      </div>
                      <div className="md:flex-1">
                        <div className="mb-12 md:-mt-2">
                          <StructuredText data={page.textUserData?.value} />
                        </div>
                        <div>
                          <FormAddress
                            isSaving={isSaving}
                            address={customer?.addresses?.[0]}
                            onSubmit={onSubmitCustomerAddress}
                          />

                          {success && (
                            <div
                              className={classNames(
                                'mt-4 p-6 rounded-4xl mb-8 bg-green-400',
                              )}
                            >
                              {success}
                            </div>
                          )}

                          {error && <p>{error}</p>}
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="mb-32 md:flex">
                    <div className="mb-6 flex-none md:w-3/12 md:mr-16">
                      <h3>{intl.formatMessage({ id: 'yourEmail' })}</h3>
                    </div>
                    <div className="md:flex-1">
                      {message ? (
                        <div
                          className={classNames(
                            'p-6 rounded-4xl mb-8 bg-green-400',
                          )}
                        >
                          {message}
                        </div>
                      ) : (
                        <FormEmail
                          isSaving={isSaving}
                          customer={user}
                          onSubmit={onSubmitCustomerEmail}
                        />
                      )}
                    </div>
                  </div>
                </>
              )}

              <div className="mt-12">
                <Button onClick={handleLogout} variant="secondary">
                  Logout
                </Button>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Account;
