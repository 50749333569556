import { Button, Input } from '@mikafi/ui';
import React, { FC } from 'react';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { useIntl } from 'react-intl';

interface Address {
  id?: string;
  firstName: string;
  lastName: string;
  company: string;
  address1: string;
  address2: string;
  zip: string;
  city: string;
  country: string;
  phone: string;
}

interface Props {
  address: Address;
  isSaving?: boolean;
  onSubmit: SubmitHandler<Address>;
}

const FormAddress: FC<Props> = ({ address, isSaving, onSubmit }) => {
  const intl = useIntl();
  const { control, handleSubmit, register } = useForm<Address>();

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="mb-4 md:flex md:gap-4 md:basis-1/2">
        {address?.id && (
          <input defaultValue={address.id} {...register('id')} hidden />
        )}

        <input defaultValue="CH" {...register('country')} hidden />

        <div className="flex-1 mb-4 md:mb-0">
          <Controller
            name="firstName"
            control={control}
            defaultValue={address?.firstName || ''}
            render={({ field }) => (
              <Input
                {...field}
                autoComplete="given-name"
                className="w-full"
                label={intl.formatMessage({ id: 'firstName' })}
                name="firstName"
                disabled={isSaving}
              />
            )}
          />
        </div>
        <div className="flex-1">
          <Controller
            name="lastName"
            control={control}
            defaultValue={address?.lastName || ''}
            render={({ field }) => (
              <Input
                {...field}
                autoComplete="family-name"
                className="w-full"
                label={intl.formatMessage({ id: 'lastName' })}
                name="lastName"
                disabled={isSaving}
              />
            )}
          />
        </div>
      </div>
      <div className="mb-4">
        <Controller
          name="company"
          control={control}
          defaultValue={address?.company || ''}
          render={({ field }) => (
            <Input
              {...field}
              className="w-full"
              label={intl.formatMessage({ id: 'company' })}
              name="company"
              disabled={isSaving}
            />
          )}
        />
      </div>
      <div className="mb-4">
        <Controller
          name="address1"
          control={control}
          defaultValue={address?.address1 || ''}
          render={({ field }) => (
            <Input
              {...field}
              autoComplete="street-address"
              className="w-full"
              label={intl.formatMessage({ id: 'address' })}
              name="address1"
              disabled={isSaving}
            />
          )}
        />
      </div>
      <div className="mb-4">
        <Controller
          name="address2"
          control={control}
          defaultValue={address?.address2 || ''}
          render={({ field }) => (
            <Input
              {...field}
              className="w-full"
              label={intl.formatMessage({ id: 'additionalAddress' })}
              name="address2"
              disabled={isSaving}
            />
          )}
        />
      </div>
      <div className="mb-4 md:flex md:gap-4">
        <div>
          <Controller
            name="zip"
            control={control}
            defaultValue={address?.zip || ''}
            render={({ field }) => (
              <Input
                {...field}
                autoComplete="postal-code"
                className="w-full md:w-32 mb-4 md:mb-0"
                label={intl.formatMessage({ id: 'zip' })}
                name="zip"
                disabled={isSaving}
              />
            )}
          />
        </div>
        <div className="flex-1">
          <Controller
            name="city"
            control={control}
            defaultValue={address?.city || ''}
            render={({ field }) => (
              <Input
                {...field}
                autoComplete="address-level2"
                className="w-full"
                label={intl.formatMessage({ id: 'city' })}
                name="city"
                disabled={isSaving}
              />
            )}
          />
        </div>
      </div>
      <div className="mb-4">
        <Controller
          name="phone"
          control={control}
          defaultValue={address?.phone || ''}
          render={({ field }) => (
            <Input
              {...field}
              type="tel"
              autoComplete="mobile tel"
              className="w-full"
              label={intl.formatMessage({ id: 'phone' })}
              name="phone"
              disabled={isSaving}
            />
          )}
        />
      </div>
      <Button type="submit" disabled={isSaving}>
        {isSaving ? 'Saving' : intl.formatMessage({ id: 'save' })}
      </Button>
    </form>
  );
};

export default FormAddress;
