import { Button, Input } from '@mikafi/ui';
import React, { FC } from 'react';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { useIntl } from 'react-intl';

interface Data {
  email?: string;
}

interface Props {
  customer: Data | null;
  isSaving?: boolean;
  onSubmit: SubmitHandler<Data>;
}

const FormEmail: FC<Props> = ({ customer, isSaving, onSubmit }) => {
  const intl = useIntl();
  const { control, handleSubmit } = useForm<Data>();

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="mb-4 md:flex md:gap-4 md:basis-1/2">
        <div className="flex-1 mb-4 md:mb-0">
          <Controller
            name="email"
            control={control}
            defaultValue={customer?.email || ''}
            render={({ field }) => (
              <Input
                {...field}
                autoComplete="email"
                className="w-full"
                label={intl.formatMessage({ id: 'email' })}
                name="email"
                disabled={isSaving}
                type="email"
              />
            )}
          />
        </div>
      </div>
      <Button type="submit" disabled={isSaving}>
        {isSaving ? 'Saving' : intl.formatMessage({ id: 'save' })}
      </Button>
    </form>
  );
};

export default FormEmail;
