import { Button, Input } from '@mikafi/ui';
import React, { FC } from 'react';
import { useIntl } from 'react-intl';

export interface Preferences {
  brewMethods?: Set<string>;
  grind?: Set<string>;
  proficiencyLevel?: string;
  additions?: string;
  roastLevel?: string;
  mood?: string;
}

interface Props {
  isSaving?: boolean;
  preferences: any;
  setPreferences: (data: Preferences | null) => void;
  onSubmit: () => void;
}

const FormPreferences: FC<Props> = ({
  isSaving,
  preferences,
  setPreferences,
  onSubmit,
}) => {
  const intl = useIntl();

  const brewMethods = new Map<string, string>([
    ['filter', intl.formatMessage({ id: 'section3BrewMethodOptionFilter' })],
    [
      'espresso',
      intl.formatMessage({ id: 'section3BrewMethodOptionEspresso' }),
    ],
    [
      'bialetti',
      intl.formatMessage({ id: 'section3BrewMethodOptionBialetti' }),
    ],
    [
      'french-press',
      intl.formatMessage({ id: 'section3BrewMethodOptionFrenchPress' }),
    ],
    [
      'cold-brew',
      intl.formatMessage({ id: 'section3BrewMethodOptionColdBrew' }),
    ],
    [
      'aeropress',
      intl.formatMessage({ id: 'section3BrewMethodOptionAeropress' }),
    ],
  ]);

  const grinds = new Map<string, string>([
    ['wholeBeans', intl.formatMessage({ id: 'section3GrindOptionWholeBeans' })],
    [
      'groundCoffee',
      intl.formatMessage({ id: 'section3GrindOptionGroundCoffee' }),
    ],
  ]);

  const proficiencyLevels = new Map<string, string>([
    [
      'newbie',
      intl.formatMessage({ id: 'section3ProficiencyLevelOptionNewbie' }),
    ],
    [
      'intermediate',
      intl.formatMessage({ id: 'section3ProficiencyLevelOptionIntermediate' }),
    ],
    [
      'advanced',
      intl.formatMessage({ id: 'section3ProficiencyLevelOptionAdvanced' }),
    ],
    [
      'expert',
      intl.formatMessage({ id: 'section3ProficiencyLevelOptionExpert' }),
    ],
  ]);

  const additions = new Map<string, string>([
    ['none', intl.formatMessage({ id: 'section3AdditionsOptionNone' })],
    [
      'milk-or-cream',
      intl.formatMessage({ id: 'section3AdditionsOptionMilkCream' }),
    ],
    [
      'sugar-or-sweetener',
      intl.formatMessage({ id: 'section3AdditionsOptionSugarSweetener' }),
    ],
    [
      'non-dairy-milk',
      intl.formatMessage({ id: 'section3AdditionsOptionNonDairyMilk' }),
    ],
    ['mix', intl.formatMessage({ id: 'section3AdditionsOptionMix' })],
  ]);

  const roastLevels = new Map<string, string>([
    ['light', intl.formatMessage({ id: 'section3RoastLevelOptionLight' })],
    ['medium', intl.formatMessage({ id: 'section3RoastLevelOptionMedium' })],
    ['dark', intl.formatMessage({ id: 'section3RoastLevelOptionDark' })],
    ['variety', intl.formatMessage({ id: 'section3RoastLevelOptionVariety' })],
    ['dunno', intl.formatMessage({ id: 'section3RoastLevelOptionDunno' })],
  ]);

  const moods = new Map<string, string>([
    ['classic', intl.formatMessage({ id: 'section3MoodOptionClassic' })],
    ['modern', intl.formatMessage({ id: 'section3MoodOptionModern' })],
    ['funky', intl.formatMessage({ id: 'section3MoodOptionFunky' })],
    ['variety', intl.formatMessage({ id: 'section3MoodOptionVariety' })],
    ['dunno', intl.formatMessage({ id: 'section3MoodOptionDunno' })],
  ]);

  return (
    <>
      <div className="grid md:grid-cols-2 gap-12">
        <div className="mb-4">
          <div className="block mb-2 font-medium">
            {intl.formatMessage({ id: 'section3BrewMethod' })}
          </div>
          {Array.from(brewMethods, ([key, value]) => (
            <label key={key} className="flex gap-2">
              <Input
                className="flex-1"
                type="checkbox"
                name="brewMethod"
                value={key}
                defaultChecked={preferences?.brewMethods?.has(key)}
                onClick={_ =>
                  preferences?.brewMethods?.has(key)
                    ? preferences?.brewMethods?.delete(key)
                    : preferences?.brewMethods?.add(key)
                }
              />
              <span className="flex-1">{value}</span>
            </label>
          ))}
        </div>

        <div className="mb-4">
          <div className="block mb-2 font-medium">
            {intl.formatMessage({ id: 'section3Grind' })}
          </div>
          {Array.from(grinds, ([key, value]) => (
            <label key={key} className="flex gap-2">
              <Input
                className="flex-1"
                type="checkbox"
                name="grind"
                value={key}
                defaultChecked={preferences?.grind?.has(key)}
                onClick={_ =>
                  preferences?.grind?.has(key)
                    ? preferences?.grind?.delete(key)
                    : preferences?.grind?.add(key)
                }
              />
              <span className="flex-1">{value}</span>
            </label>
          ))}
        </div>

        <div className="mb-4">
          <div className="block mb-2 font-medium">
            {intl.formatMessage({ id: 'section3ProficiencyLevel' })}
          </div>
          {Array.from(proficiencyLevels, ([key, value]) => (
            <label key={key} className="flex gap-2">
              <Input
                className="flex-1"
                type="radio"
                name="proficiencyLevel"
                value={key}
                checked={preferences?.proficiencyLevel == key}
                onChange={e => {
                  setPreferences({
                    ...preferences,
                    proficiencyLevel: e.target.value,
                  });
                }}
              />
              <span className="flex-1">{value}</span>
            </label>
          ))}
        </div>

        <div className="mb-4">
          <div className="block mb-2 font-medium">
            {intl.formatMessage({ id: 'section3Additions' })}
          </div>
          {Array.from(additions, ([key, value]) => (
            <label key={key} className="flex gap-2">
              <Input
                className="flex-1"
                type="radio"
                name="additions"
                value={key}
                checked={preferences?.additions == key}
                onChange={e => {
                  setPreferences({
                    ...preferences,
                    additions: e.target.value,
                  });
                }}
              />
              <span className="flex-1">{value}</span>
            </label>
          ))}
        </div>

        <div className="mb-4">
          <div className="block mb-2 font-medium">
            {intl.formatMessage({ id: 'section3RoastLevel' })}
          </div>
          {Array.from(roastLevels, ([key, value]) => (
            <label key={key} className="flex gap-2">
              <Input
                className="flex-1"
                type="radio"
                name="roastLevel"
                value={key}
                checked={preferences?.roastLevel == key}
                onChange={e => {
                  setPreferences({
                    ...preferences,
                    roastLevel: e.target.value,
                  });
                }}
              />
              <span className="flex-1">{value}</span>
            </label>
          ))}
        </div>

        <div className="mb-4">
          <div className="block mb-2 font-medium">
            {intl.formatMessage({ id: 'section3Mood' })}
          </div>
          {Array.from(moods, ([key, value]) => (
            <label key={key} className="flex gap-2">
              <Input
                className="flex-1"
                type="radio"
                name="mood"
                value={key}
                checked={preferences?.mood == key}
                onChange={e => {
                  setPreferences({
                    ...preferences,
                    mood: e.target.value,
                  });
                }}
              />
              <span className="flex-1">{value}</span>
            </label>
          ))}
        </div>
      </div>

      <Button onClick={onSubmit} disabled={isSaving}>
        {isSaving ? 'Saving' : intl.formatMessage({ id: 'save' })}
      </Button>
    </>
  );
};

export default FormPreferences;
