import React, { useEffect } from 'react';
import { Router } from '@reach/router';
import Layout from '@/components/Layout';
import Account from '@/views/Account';
import { useUser } from '@/hooks/useUser';
import { useIntl } from 'react-intl';
import { graphql, navigate, PageProps } from 'gatsby';

type PageContextProps = {};

type DataProps = {
  datoCmsAccount: any;
};

const Index = ({ data }: PageProps<DataProps, PageContextProps>) => {
  const intl = useIntl();
  const { user, isLoading } = useUser();

  useEffect(() => {
    if (!user && !isLoading) {
      navigate(`/${intl.locale}/login/`);
    }
  }, [user, isLoading]);

  if (isLoading || !user) return null;

  return (
    <Layout
      variant="dark"
      navChangeColor={false}
      alternatePages={[
        { locale: 'de', value: 'account' },
        { locale: 'en', value: 'account' },
      ]}
    >
      <Router basepath={`/:lang/account/`}>
        <Account data={data} path="/" />
      </Router>
    </Layout>
  );
};

export const query = graphql`
  query ($locale: String) {
    datoCmsAccount(locale: { eq: $locale }) {
      title
      facts {
        title
        subtitle
        text
        id
      }
      textBenefits {
        value
        blocks
      }
      textPreferences {
        value
        blocks
      }
      textOrders {
        value
        blocks
      }
      textUserData {
        value
        blocks
      }
    }
  }
`;

export default Index;
