import { Button } from '@mikafi/ui';
import classNames from 'classnames';
import React, { FC } from 'react';
import { useIntl } from 'react-intl';

interface Props {
  order: any;
}

const OrderItem: FC<Props> = ({ order }) => {
  const intl = useIntl();

  const date = new Date(order.processedAt);

  const sendEmail = () => {
    window.location.href = `mailto:hello@mikafi.com?subject=Bestellung ${order.name}`;
  };

  return (
    <div className="flex flex-wrap sm:flex-nowrap border-b py-4 items-center">
      <div className="w-1/2 mb-3 sm:mb-0 sm:w-1/5">
        <span className="block sm:hidden font-medium uppercase text-xs text-gray-400 mb-1">
          Order nr.
        </span>
        {order.name}
      </div>
      <div className="w-1/2 mb-3 sm:mb-0 sm:w-1/5 text-right sm:text-left">
        <span className="block sm:hidden font-medium uppercase text-xs text-gray-400 mb-1">
          Order date
        </span>
        {new Intl.DateTimeFormat(`${intl.locale}-CH`, {
          month: '2-digit',
          day: '2-digit',
          year: 'numeric',
        }).format(date)}
      </div>

      <div className="w-1/2 mb-3 sm:mb-0 sm:w-1/5">
        <span className="block sm:hidden font-medium uppercase text-xs text-gray-400 mb-1">
          Amount
        </span>

        {new Intl.NumberFormat('de-CH', {
          style: 'currency',
          currency: order.currentTotalPriceSet.shopMoney.currencyCode,
        }).format(order.currentTotalPriceSet.shopMoney.amount)}
      </div>
      <div className="w-1/2 mb-3 sm:mb-0 sm:w-1/5 text-right sm:text-left">
        <span className="block sm:hidden font-medium uppercase text-xs text-gray-400 mb-1">
          Status
        </span>
        <span
          className={classNames(
            'no-underline text-xs py-1 px-3 rounded-full text-white',
            {
              'bg-red-400': order.cancelReason,
              'bg-[#C2E28E]':
                order.displayFulfillmentStatus === 'FULFILLED' &&
                !order.cancelReason,
              'bg-orange-400':
                order.displayFulfillmentStatus === 'UNFULFILLED' &&
                !order.cancelReason,
            },
          )}
        >
          {order.cancelReason
            ? 'Cancelled'
            : order.displayFulfillmentStatus === 'FULFILLED'
            ? 'Done'
            : 'Roasting'}
        </span>
      </div>
      <div className="w-1/2 mb-3 sm:mb-0 sm:w-1/5">
        <Button size="small" className="py-1 text-xs" onClick={sendEmail}>
          Feedback
        </Button>
      </div>
    </div>
  );
};

export default OrderItem;
